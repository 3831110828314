import React from "react"
import styled from "styled-components"

import Layout from "src/layout"
import { SIZES } from "src/layout/constants"

const Content = styled.section`
  margin: ${SIZES.mobileMargin}px auto;
  padding: 0 ${SIZES.mobileMargin}px ${SIZES.mobileMargin}px;
  @media (min-width: 800px) {
    max-width: ${SIZES.maxWidth}px;
    margin: ${SIZES.margin}px auto;
    padding: ${SIZES.margin}px;
  }
  p {
    line-height: 2em;
  }
  h2 {
    margin-top: 2em;
  }
`

const LegalPage: GatsbyPage = () => (
  <Layout title="legal">
    <Content>
      <h1>Mentions légales</h1>
      <p>
        Collectif Premices – SAS au capital de 2000€
        <br />
        38 rue Servan 75544 Paris Cedex 11
        <br />
        SIRET : 798 289 609 00033 – RCS Paris
        <br />
        Nº TVA : FR 46 798289609 – NAF 74 10Z
      </p>
      <h2>Éditeurs du site</h2>
      <p>
        Création : <a href="https://antoine.rousseau.im/">Antoine Rousseau</a>
        <br />
        Hébergement : <a href="https://www.netlify.com">Netlify</a> et{" "}
        <a href="https://www.contentful.com">Contentful</a>
      </p>
    </Content>
  </Layout>
)

export default LegalPage
